import Bandive from '../assets/images/projects/Bandive.jpg'
import Billify from '../assets/images/projects/Billify.jpg'
import Blackjack from '../assets/images/projects/Blackjack.jpg'
import DragonSlayer from '../assets/images/projects/DragonSlayer.jpg'
import MagicDateBall from '../assets/images/projects/MagicDateBall.jpg'
import Mixd from '../assets/images/projects/Mixd.jpg'
import MuveeTix from '../assets/images/projects/MuveeTix.jpg'
import Proma from '../assets/images/projects/Proma.jpg'
import Recipeek from '../assets/images/projects/Recipeek.jpg'

const projects = [
  {
    name: 'Proma',
    description:
      'A simple project management application that allows you to create projects and tasks for your clients.',
    image: Proma,
    link: 'https://proma.chaseterry.com',
    github: 'https://github.com/cdterry87/Proma',
    tech: ['Tailwind', 'Alpine.js', 'Laravel', 'Livewire', 'TDD']
  },
  {
    name: 'Bandive',
    description: "Uses Spotify's API to find recommendations based on your favorite artists.",
    subtext: 'NOTE: As of November 2024, Spotify deprecated many of the API features that made this app function properly.',
    image: Bandive,
    link: 'https://bandive.chaseterry.com/',
    github: 'https://github.com/cdterry87/bandive',
    tech: ['Next.js', 'React', 'Tailwind', 'Netlify']
  },
  {
    name: 'Billify',
    description:
      'A bill management application that enables you to add your monthy bills and calculate your debt/income ratio.',
    image: Billify,
    link: 'https://billify.chaseterry.com/',
    github: 'https://github.com/cdterry87/Billify',
    tech: ['Tailwind', 'Alpine.js', 'Laravel', 'Livewire', 'TDD']
  },
  {
    name: 'Magic Date Ball',
    description:
      "Uses the Yelp API to find restaurants in your area when you can't decide where to eat.",
    image: MagicDateBall,
    link: 'https://magicdateball.chaseterry.com/',
    github: 'https://github.com/cdterry87/magicdateball',
    tech: ['Next.js', 'React', 'Tailwind', 'Netlify']
  },
  {
    name: 'Recipeek',
    description:
      'Find and save recipes and add ingredients to your shopping list.',
    image: Recipeek,
    link: '#',
    github: '#',
    tech: ['TBD']
  },
  {
    name: 'Dragon Slayer',
    description: 'A small browser game where a hero fights a dragon.',
    image: DragonSlayer,
    link: 'https://cdterry87.github.io/dragonslayer/',
    github: 'https://github.com/cdterry87/dragonslayer',
    tech: ['Vue.js', 'Bulma']
  },
  {
    name: 'MIXD',
    description:
      'Originally a mixed drink finder using The Cocktail DB API, but also expanded to use The Meal DB API to include a meal and recipe finder.',
    image: Mixd,
    link: 'https://cdterry87.github.io/mixd/',
    github: 'https://github.com/cdterry87/mixd',
    tech: ['Vue.js', 'Bulma']
  },
  {
    name: 'Muvee Tix',
    description:
      'A movie ticket purchasing simulator that uses the TMDB API to select a movie, select your seats, and buy your tickets and snacks.',
    image: MuveeTix,
    link: 'https://cdterry87.github.io/muvee-tix/',
    github: 'https://github.com/cdterry87/muvee-tix',
    tech: ['Vue.js', 'Bulma']
  },
  {
    name: 'Blackjack',
    description: 'A simple Blackjack game in React.',
    image: Blackjack,
    link: 'https://cdterry87.github.io/blackjack',
    github: 'https://github.com/cdterry87/blackjack',
    tech: ['React', 'Tailwind']
  }
]

export default projects